<template>
  <div>
    <ContactoS1/>
  </div>
</template>
<script>
// @ is an alias to /src
import ContactoS1 from '@/components/Contacto/ContactoS1.vue'
import { useMeta } from 'vue-meta'
import store from "@/store";

export default {
  name: 'Contacto',
  components: {
    ContactoS1
  },
  setup() {
    useMeta({
      title:'Contacta con nosotros y no te quedes con dudas',
      meta:[
        {name:'robots', content:store.state.dominio == 'ibancar.com' ? 'index' : 'noindex'},
        {name:'description', content:'Para cualquier duda o información, estamos a tu disposición de lunes a viernes de 9:00 - 17:30 y los sábados de 10:00 a 14:00.'},
        {name:'keywords', content:'Préstamo aval coche, Dinero urgente online, Crédito con coche, Préstamos rápidos y créditos rápidos de dinero online. Empeña tu coche. Refinanciación de coches.'}
      ]
    })
  }
}
</script>